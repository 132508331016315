<template>
  <div class="d-flex flex-column justify-content-end">
    <div class="">
      <div class="quizell-ai-title" style="margin-bottom: 25px;">
       What type of Result page do you prefer? </div>

    </div>
    <div class="row">
     
     <div class="col-6 col-md-6"> <div class="card"
        style="border-radius: 12px;  border: 1px solid rgba(24, 25, 28, 0.08);">
        <div data-v-5f0b3796="" class="card-body info"> 
          <div class="">
            <lazy-image class="w-100" src="/assets/img/AiCreateQuizThankYou.png"></lazy-image>
          </div>
          <div class="">
            <div class="ai-endPage-title my-2">
              Use thank you page
            </div>
            <div class="ai-endPage-description">
              If no products are found, select a Thank you page template
            </div>
          </div>
        </div>  
        </div>
      </div>

      <div class="col-6 col-md-6"> <div class="card"
        style="border-radius: 12px; border: 1px solid rgba(24, 25, 28, 0.08);">
        <div data-v-5f0b3796="" class="card-body info"> 
          <div class="">
            <lazy-image class="w-100" src="/assets/img/MatchwithAI.png"></lazy-image>
          </div>
          <div class="">
            <div class="ai-endPage-title my-2">
              Match my products with AI
            </div>
            <div class="ai-endPage-description">
              Add or Import Products from your store by using AI
            </div>
          </div>
        </div>  
        </div>
      </div>
    </div>
  
    <!-- <div class="mt-2" style="box-shadow: 0px -2px 20px 0px rgba(24, 25, 28, 0.08);"></div> -->
    <div class="row box-with-shadow mt-3" >
            <div class="col-12 d-flex justify-content-end">
                <Button @click="$emit('click')" class="mr-2" style="background: #B3AFB6; min-width: 20px;">Skip </Button>
                <Button @click="$emit('click')">Next Step <span class="mr-1"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M3.33334 9.16669V10.8334H13.3333L8.75001 15.4167L9.93334 16.6L16.5333 10L9.93334 3.40002L8.75001 4.58336L13.3333 9.16669H3.33334Z" fill="white"/>
    </svg></span> </Button>
            </div>
        </div>
 
  </div>

</template>
<script>
import Button from '../../Layout/Button.vue'
export default {
  components: {
    Button
  },
  watch: {
   
  },
  data() {
    return {
      text: ''
    }
  }
}
</script>
<style scoped>
.card:hover,.activeCard{
border: 1px solid #4D1B7E!important;
}
.card:active {
  box-shadow: -0.0625rem 0 0.0625rem 0 rgba(26, 26, 26, 0.122) inset,
    0.0625rem 0 0.0625rem 0 rgba(26, 26, 26, 0.122) inset,
    0 0.125rem 0.0625rem 0 #1a1a1a33 inset !important;
}
.ai-endPage-title{
  color: #1F1F28;

font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 100%; /* 16px */
letter-spacing: 0.64px;
}
.ai-endPage-description{
  color: #73738D;

font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 16.8px */
letter-spacing: 0.48px;
}
 .box-with-shadow {
        position: relative;
        
    }

    /* .box-with-shadow::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 5px; 
        background: rgba(0, 0, 0, 0.2); 
        box-shadow: 0px 5px 5px -5px rgba(0, 0, 0, 0.8); 
    } */
.card-body div span{
  color: #73738D;

font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 100%; /* 16px */
letter-spacing: 0.64px;
}
.checkbox >>> .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #4D1B7E;
  background-color: #4D1B7E !important;
}
.checkbox >>> .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem #4D1B7E;
}
.importQuizTextArea {
  border: none;
  outline: none;
  resize: none;
}

.importQuizTextArea:active {
  border: none;
  outline: none;

}

.info {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.quizell-ai-info {
  color: #1F1F28;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.56px;
}

.quizell-ai-title {
  color: #1F1F28;

  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;

  letter-spacing: 0.88
}
</style>
